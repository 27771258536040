/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Content from 'components/shared/Content'

import KPN from 'img/KPN-Logo.inline.svg'
import Vodafone from 'img/vodafone.inline.svg'

const LogoKPN = styled(KPN)`
  width: 200px;
  height: auto;
  margin-bottom: 1.5rem;
`

const LogoVodafone = styled(Vodafone)`
  width: 200px;
  height: auto;
  margin-bottom: 1.5rem;
`

const StatusRed = styled.div`
  width: 50px;
  height: 50px;
  background: red;
  border-radius: 50px;
  background: rgb(242, 144, 48);
  display: block;
  text-align: center;
  margin: 0 auto;
`

const StatusGreen = styled.div`
  width: 50px;
  height: 50px;
  background: red;
  border-radius: 50px;
  background: #3bd671;

  display: block;
  text-align: center;
  margin: 0 auto;

`

const Frame = styled.div`
  border: 2px inset;
  @media (min-width: 992px){
    width: 80%;
    max-height: 500px;
    overflow-y: scroll;
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...StatusPageFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero small className="mb-lg-5" content={acf.banner} />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <LogoKPN />
              <Content content={acf.content.description} />
            </div>
          </div>
            <div className="row py-3 py-lg-5">
              <div className="col-12">
                <LogoVodafone />
                <Content content={acf.content_vodafone.description} />
              </div>
            </div>
        </div>
      </section>

    </Layout>
  )
}

export default PageTemplate
